import React from "react";
import SubHeading from "./SubHeading";

const Details = () => {
  return (
    <div className="flex flex-col h-full bg-white p-4 md:rounded">
      <SubHeading heading={"IndicVoices"} />
      <p className="text-sm">
        INDICVOICES is a dataset of natural and spontaneous speech containing a
        total of 16347 hours of read (8%), extempore (76%) and conversational
        (15%) audio from 25K speakers covering 308 Indian districts and 22
        languages. Of these 16347 hours, 7256 hours have already been
        transcribed. Through this paper,
        we share our journey of capturing the cultural, linguistic and
        demographic diversity of India to create a one-of-its-kind inclusive and
        representative dataset. More specifically, we share an open-source
        blueprint for data collection at scale comprising of standardised
        protocols, centralised tools, a repository of engaging questions,
        prompts and conversation scenarios spanning multiple domains and topics
        of interest, quality control mechanisms, comprehensive transcription
        guidelines and transcription tools. We hope that this open source
        blueprint will serve as a comprehensive starter kit for data collection
        efforts in other multilingual regions of the world. Using INDICVOICES,
        we build IndicASR, the first ASR model to support all the 22 languages
        listed in the 8th schedule of the Constitution of India.
      </p>
    </div>
  );
};

export default Details;
