import React, { useState } from "react";
import SubHeading from "./SubHeading";

const License = () => {
  return (
    <div className="bg-white p-4 md:rounded">
      <SubHeading heading={"License"} />
      <p>IndicVoices is released under CC-BY-4.0 license</p>
    </div>
  );
};

export default License;
